<template>
  <button
    :class="[
      'button',
      `button--${variant}`,
      { 'button--small': small },
      { 'button--large': large },
      { 'button--disabled': disabled || loading },
      { 'button--loading': loading },
      { 'button--icon-right': iconRight },
      { 'button--icon-only': iconOnly },
    ]"
    :to="to"
    @click.stop="click"
    :disabled="disabled || loading"
  >
    <Icon
      v-if="!iconRight"
      :name="loading ? 'rotate-ccw' : icon"
    />

    <slot v-if="!iconOnly" />

    <Icon
      v-if="iconRight"
      :name="loading ? 'rotate-ccw' : icon"
    />
  </button>
</template>

<script>
import { useRouter } from 'vue-router';
import Icon from './Icon';

export default {
  components: {
    Icon,
  },
  props: {
    variant: {
      type: String,
      default: 'secondary',
    },
    icon: {
      type: String,
      default: '',
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'click',
  ],
  setup(props, { emit }) {
    const router = useRouter();

    const click = () => {
      if (props.disabled || props.loading) return;

      if (props.to) {
        router.push(props.to);
      } else {
        emit('click');
      }
    };

    return {
      click,
    };
  },
};
</script>

<style lang="scss">
.button {
  display: inline-flex;
  align-items: center;
  background-color: $buttonBackgroundColor;
  border: $buttonBorder;
  border-radius: $buttonBorderRadius;
  padding: $buttonPadding;
  font-size: $buttonFontSize;
  transition: $buttonBoxShadowTransition;
  user-select: none;
  height: $buttonHeight;
  white-space: nowrap;

  & > .icon {
    margin-right: 4px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $buttonHoverBackgroundColor;
  }

  &:focus {
    outline: none;
    box-shadow: $componentFocusBoxShadow;
  }
}

.button.button--primary {
  background-color: $sidebarTogglerBackgroundColor;
  border-color: $sidebarTogglerBackgroundColor;
  color: $sidebarTogglerTextColor;
  stroke: $sidebarTogglerTextColor;

  &:hover,
  &:focus {
    background-color: $sidebarTogglerHoverBackgroundColor;
    border-color: $sidebarTogglerHoverBackgroundColor;
  }
}

.button.button--tertiary {
  background-color: $white;
  border-color: $white;
  color: $black;
  stroke: $black;

  &:hover,
  &:focus {
    background-color: #FAFAFA;
    border-color: #DDDDDD;
  }
}

.button.button--danger {
  background-color: $error500;
  border-color: $error500;
  color: $white;

  &:hover,
  &:focus {
    background-color: $error600;
  }
}

.button.button--danger-secondary {
  background-color: $white;
  border-color: $gray500;
  color: $error500;

  &:hover,
  &:focus {
    background-color: $gray300;
    border-color: $gray600;
  }
}

.button.button--small {
  padding: $buttonSmallPadding;
  height: $buttonSmallHeight;
}

.button.button--large {
  padding: $buttonSmallPadding;
  height: 44px;
}

.button.button--disabled {
  background-color: $gray500;
  border-color: $gray500;
  color: $gray700;
  fill: $gray700;
  stroke: $gray700;
  cursor: not-allowed;

  &:hover,
  &:focus {
    background-color: $gray500;
    border-color: $gray500;
  }
}

.button.button--loading {
  cursor: not-allowed;

  .icon {
    animation: rotate-ccw 1000ms infinite;
  }
}

.button.button--icon-right {
  & > .icon {
    margin-right: 0;
    margin-left: 4px;
  }
}

.button.button--icon-only {
  & > .icon {
    margin-right: 0;
  }
}
</style>
